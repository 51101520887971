var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 29 24"
    }
  }, [_c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M1.99 10.332c7.775-3.387 12.958-5.62 15.552-6.699C24.948.553 26.487.018 27.49 0c.22-.004.713.051 1.033.31.27.22.344.515.38.722.035.208.08.68.044 1.05-.401 4.217-2.138 14.45-3.021 19.172-.374 1.998-1.11 2.668-1.822 2.734-1.55.143-2.725-1.023-4.225-2.006-2.347-1.539-3.673-2.497-5.951-3.998-2.633-1.735-.926-2.689.574-4.247.393-.408 7.216-6.614 7.348-7.177.017-.07.032-.333-.124-.471-.156-.139-.386-.092-.552-.054-.235.053-3.985 2.532-11.248 7.435-1.064.73-2.028 1.086-2.892 1.068-.952-.02-2.784-.539-4.145-.981-1.67-.543-2.998-.83-2.882-1.752.06-.48.721-.971 1.984-1.473Z"
    }
  })]);

}
var staticRenderFns = []

export { render, staticRenderFns }